html {
  position: relative;
  min-height: 100%;
}

@media (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}

.container {
  max-width: 1500px;
}

body {
  margin: 0;
  margin-bottom: 50px;
  margin-top: 50px;
  background-color: #eee;
  /* bottom = footer height */
  /* padding: 25px; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-filter-column-left {
  border-right: 1px solid lightgrey;
}

.dropdown-wide {
  width: 800px;
}

.dropdown-menu {
  z-index: 9999;
}

button[disabled] {
  color: grey;
}

.filepond--item {
  width: calc(25% - .5em);
}

img {
  max-width: 100%;
}

.image {
  position: relative;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.image-grid {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
}

.image-grid img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  float: left;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .image-grid img {
    width: 100%; /* on mobile devices, image takes full width */
  }
}

.grid img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  float: left;
}

.userIcon {
  position: absolute;
  right: 10px;
  bottom: 75px;
  border-radius: 120px;
  width: 44px;
  height: 44px;
  line-height: 45px;
  font-size: 32px;
  background-size: cover;
  background-position: center;
  background-color: #aaa;
  color: white;
  text-align: center;
  border: 2px solid white;
  float: right;
}

.leaflet-container {
  height: 600px;
}

.map-small {
  height: 400px;
  width: 100%;
}

.map-tiny {
  height: 300px;
  width: 100%;
}

.color-grey {
  color: grey;
}

.card {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 10px;
  /* max-width: 300px; */
}

/* .card-deck .card {
  max-width: calc(25% - 30px);
} */

.auth-form {
  max-width: 300px;
  margin: 0 auto;
  margin-top: 100px;
  background-color: #fff;
  border: 1px solid #d8dee2;
  border-top: 1px solid #d8dee2;
  border-radius: 5px;
  /* width: "100%";
  height: "100vh";
  display: "flex";
  justify-content: "center";
  align-items: "center"; */
}

.auth-form-body {
  max-width: 280px;
  margin: 0 auto;
  padding: 20px;
  font-size: 14px;
}

.auth-form-wide {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 70px;
  background-color: #fff;
  border: 1px solid #d8dee2;
  border-top: 1px solid #d8dee2;
  border-radius: 5px;
  /* width: "100%";
  height: "100vh";
  display: "flex";
  justify-content: "center";
  align-items: "center"; */
}

.auth-form-body-wide {
  max-width: 780px;
  margin: 0 auto;
  padding: 20px;
  font-size: 14px;
}

.footer {
  background-color: #525252;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  overflow: hidden;
}

.footer-div {
  color: white;
  text-align: center;
  padding-top: 15px;
}

@media screen and (max-width: 480px) {
  .nav {
    padding-left: 2px;
    padding-right: 2px;
  }

  .nav li {
    display: block !important;
    width: 100%;
    margin: 0px;
  }

  .nav li.active {
    border-bottom: 1px solid #ddd !important;
    margin: 0px;
  }
}

.nav-link.active {
  text-decoration: underline;
  text-decoration-color: #28a745;
  text-underline-offset: 5px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  /* justify-content: center; */
}

.image {
  margin: 5px;
}

.col {
  padding: 0;
  margin: 0;
}

.panel {
  border: 1px solid #CCC;
  border-radius: 5px;
  padding: 20px;
  background-color: #fff;
}

@media (max-width: 600px) {
  .card {
    padding: 0;
    margin: 0
      /* background-color: rgb(143, 12, 12);  */
  }

  .col {
    padding: 0;
    margin: 0;
  }

  /* .container {
    padding:0;
    margin:0;
  } */
}

.stats-div {
  float: right;
}

@media (max-width: 600px) {
  .stats-div {
    float: none;
  }
}

@media (max-width: 600px) {
  .hide-small-screen {
    display: none;
  }
}

/* .navbar-header .navbar-brand {
  height: 70px;
} */

.legend-circle {
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  opacity: 1;
  display: inline-block;
}

.legend-square {
  background: white;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  display: inline-block;
  opacity: 1;
}

.legend-text {
  display: inline-block;
  height: 20px;
  margin-top: 4px;
  line-height: 20px;
}

.legend {
  line-height: 18px;
  color: #555;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  line-height: 16px;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.sr-only {
  display: none;
}

.DayPickerInput {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.DayPickerInput-OverlapWrapper {
  z-index: 1000;
}

/* input {
  border: 0;
  width: 100%;
} */

.react-datepicker-popper {
  z-index: 12000 !important;
}

.flow-layout {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-items: stretch;
  padding: 20px;
  border-spacing: 1.25rem 0;
}

.leaflet-popup {
  z-index: 800;
}

#popup-observation {
  display: flex;
}

#popup-observation-left {
  flex: 0 0 50%;
  margin: auto;
}

#popup-observation-right {
  flex: 1;
  padding: 10px;
}

.leaflet-popup-content {
  width: 500px;
}

.leaflet-popup-content p {
  margin: 0px 0;
}

/* a:link {
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: black;
}

a:active {
  text-decoration: none;
  color: black;
} */

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1rem;
  }

  .footer {
    font-size: 0.6rem;
  }

  h1 {
    font-size: 2rem;
  }
}

.tk25tooltip {
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

input[type='checkbox'] {
  -webkit-appearance:none;
  width:30px;
  height:30px;
  background:white;
  border-radius:5px;
  border:2px solid #555;
}
input[type='checkbox']:checked {
  background: #abd;
}

.map-artportrait {
  height: 500px;
}

.col-padding {
  padding-left: 15px;
  padding-right: 15px;
}
